<!-- 组件代码如下 -->
<template>
	<div class="quillEditorbox">
		<el-upload class="avatar-uploader quillEditor none" :action="picurl" :headers="uploadHeader" :show-file-list="false" multiple :on-success="uploadSuccess" :on-error="uploadError" :before-upload="beforeUpload" accept=".png,.jpg,jpeg,.gif,.bmp,.PNG,.JPG,JPEG,.GIF,.BMP"></el-upload>
		<quill-editor v-model="content" ref="myQuillEditor" :class="{defaultColor:withMaxLength}" :options="editorOption" @change="onEditorChange($event)" @ready="onEditorReady($event)"> </quill-editor>
		<p class="eidotr-text" v-if="!!maxLength && withMaxLength">
			<span>{{ currentLength }}</span>
			<span>/</span>
			<span>{{ maxLength }}</span>
		</p>
	</div>
</template>
<script>
// import { quillRedefine } from "vue-quill-editor-upload";
import { quillEditor } from "vue-quill-editor";
const toolbarOptions = [
	// 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
	["bold", "italic", "underline", "strike"],
	// 引用  代码块-----['blockquote', 'code-block']
	// ['blockquote', 'code-block'],
	// 1、2 级标题-----[{ header: 1 }, { header: 2 }]
	[{ header: 1 }, { header: 2 }],
	// 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
	// [{ list: 'ordered' }, { list: 'bullet' }],
	// 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
	[{ script: "sub" }, { script: "super" }],
	// 缩进-----[{ indent: '-1' }, { indent: '+1' }]
	// [{ indent: '-1' }, { indent: '+1' }],
	// 文本方向-----[{'direction': 'rtl'}]
	// [{ direction: 'rtl' }],
	// 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
	[{ size: ["small", false, "large", "huge"] }],
	// 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
	// [{ header: [1, 2, 3, 4, 5, 6, false] }],
	// 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
	[{ color: [] }],
	// 字体种类-----[{ font: [] }]
	// [{ font: [] }],
	// 对齐方式-----[{ align: [] }]
	[{ align: [] }],
	// 清除文本格式-----['clean']
	["clean"],
	// 链接、图片、视频-----['link', 'image', 'video']
	["image"],
];
const withMaxBarOption = [
	// 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
	["bold", "italic", "underline", "strike"],
	// 引用  代码块-----['blockquote', 'code-block']
	// ['blockquote', 'code-block'],
	// 1、2 级标题-----[{ header: 1 }, { header: 2 }]
	[{ header: 1 }, { header: 2 }],
	// 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
	// [{ list: 'ordered' }, { list: 'bullet' }],
	// 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
	[{ script: "sub" }, { script: "super" }],
	// 缩进-----[{ indent: '-1' }, { indent: '+1' }]
	// [{ indent: '-1' }, { indent: '+1' }],
	// 文本方向-----[{'direction': 'rtl'}]
	// [{ direction: 'rtl' }],
	// 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
	[{ size: ["small", false, "large", "huge"] }],
	// 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
	// [{ header: [1, 2, 3, 4, 5, 6, false] }],
	// 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
	// [{ color: [] }],
	// 字体种类-----[{ font: [] }]
	// [{ font: [] }],
	// 对齐方式-----[{ align: [] }]
	[{ align: [] }],
	// 清除文本格式-----['clean']
	["clean"],
	// 链接、图片、视频-----['link', 'image', 'video']
	["image"],
];
export default {
	props: ["quillcon", "uploadApi", "withMaxLength"],
	name: "qe",
	components: {
		quillEditor,
		// quillRedefine
	},
	data() {
		return {
			maxLength: 50000,
			currentLength: 0,
			picurl: "",
			content: this.quillcon,
			uploadHeader: {},
			editorOption: {
				modules: {
					toolbar: {
						container: this.withMaxLength ? withMaxBarOption : toolbarOptions, // 工具栏
						handlers: {
							image: function (value) {
								if (value) {
									document.querySelector(".quillEditor input").click();
								} else {
									this.quill.format("image", false);
								}
							},
						},
					},

					history: {
						delay: 1000,
						maxStack: 50,
						userOnly: false,
					},

					clipboard: {
						matchers: [[Node.ELEMENT_NODE, this.handleCustomMatcher]],
					},
				},
				placeholder: "请输入内容",
			}, // 必须初始化为对象 init  to Object
		};
	},
	mounted() {
		// console.log(this.quillcon);
		// 设置文本悬浮提示
		// document.querySelector('[data-placeholder]').setAttribute('data-placeholder', '请输入内容');
		let _hostname = location.hostname == "localhost" ? "http://182.151.11.69:41070" : location.origin;
		this.picurl = _hostname + "/v1/common/uploadFile"; //配置文件参数
		this.uploadHeader.token = this._storage.LStorage.get("_t");
	},
	methods: {
		// 清除空格外的所有格式
		handleCustomMatcher(node, delta) {
			delta.ops = delta.ops.map((op) => {
				return {
					insert: op.insert,
				};
			});
			return delta;
		},
		// 上传图片前
		beforeUpload(res, file) {},
		// 上传图片成功
		uploadSuccess(res, file) {
			let quill = this.$refs.myQuillEditor.quill;
			// 如果上传成功
			if (res.data && res.data.fileSign) {
				// 获取光标所在位置
				let length = quill.getSelection().index;
				// 插入图片  res.info为服务器返回的图片地址
				quill.insertEmbed(length, "image", res.data.fileSign);
				// 调整光标到最后
				quill.setSelection(length + 1);
			} else {
				this.$message.error("图片插入失败");
			}
			// loading动画消失
			this.quillUpdateImg = false;
		},
		// 上传图片失败
		uploadError(res, file) {
			this.quillUpdateImg = false;
			this.$message.error("图片插入失败");
		},
		getUEContent() {
			// 获取内容方法
			return this.editor.getContent();
		},
		onEditorBlur() {
			//失去焦点事件
		},
		onEditorFocus() {
			//获得焦点事件
		},
		onEditorChange(e) {
			//内容改变事件
			if (this.withMaxLength) {
				this.$nextTick(() => {
					e.quill.deleteText(this.maxLength, 4);
					this.currentLength = e.quill.getLength() - 1;
				});
			}
		},
		onEditorReady(editor) {},
	},
	destroyed() {},
	watch: {
		quillcon(val) {
			this.content = val;
		},
		content(val) {
			//设置监听，如果改变就更新 p_model
			this.$emit("update:quillcon", val);
		},
	},
};
</script>
<style lang="scss">
.quillEditorbox {
	position: relative;
	.ql-container {
		height: 280px;
		overflow-y: scroll;
	}

	.defaultColor {
		color: #333;
	}

	.eidotr-text {
		position: absolute;
		right: 8px;
		bottom: 4px;
		font-size: 14px;
		text-shadow: 0px 0px 1px #fff;
	}

	.quillEditorbox .editor {
		line-height: normal !important;
		height: 800px;
	}

	.ql-snow .ql-tooltip[data-mode="link"]::before {
		content: "请输入链接地址:";
	}

	.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
		border-right: 0px;
		content: "保存";
		padding-right: 0px;
	}

	.ql-snow .ql-tooltip[data-mode="video"]::before {
		content: "请输入视频地址:";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item::before {
		content: "14px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
		content: "10px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
		content: "18px";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
		content: "32px";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item::before {
		content: "文本";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
		content: "标题1";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
		content: "标题2";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
		content: "标题3";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
		content: "标题4";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
		content: "标题5";
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
		content: "标题6";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item::before {
		content: "标准字体";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
		content: "衬线字体";
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
		content: "等宽字体";
	}
}
</style>
