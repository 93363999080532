let Home = () => import("../views/home.vue");
let Home1 = () => import("../views/home1.vue");

const basicRoutes = [
	{
		path: "/",
		name: "",
		title: "首页",
		redirect: "/search",
	},
	{
		path: "/search",
		name: "search",
		title: "首页",
		redirect: "",
		component: () => import("../views/search.vue"),
	},
	{
		path: "/transfer",
		name: "transfer",
		title: "对外登录",
		redirect: "",
		component: () => import("../views/userInfo/transfer.vue"),
	},
	{
		path: "/visition",
		name: "visition",
		title: "演示登录",
		redirect: "",
		component: () => import("../views/userInfo/visition.vue"),
	},
	// 管理端登录
	{
		path: "/login",
		name: "login",
		title: "登录",
		redirect: "",
		component: () => import("../views/userInfo/login.vue"),
	},
	{
		path: "/register",
		name: "register",
		title: "注册",
		redirect: "",
		component: () => import("../views/userInfo/register.vue"),
	},
	{
		path: "/findPwdBack",
		name: "findPwdBack",
		title: "找回密码",
		redirect: "",
		component: () => import("../views/userInfo/findPwdBack.vue"),
	},
	// 管理端 ===================================================
	{
		path: "/adminIndex1",
		name: "adminIndex1",
		title: "首页",
		redirect: "",
		component: Home1,
		children: [
			{
				path: "/adminIndex",
				name: "adminIndex",
				title: "首页",
				redirect: "",
				component: () => import("../views/adminManage/adminHome.vue"),
			},
		],
	},
	{
		path: "/business",
		name: "business",
		title: "企业管理",
		redirect: "/business/enterpriseList",
		component: Home,
		children: [
			{
				path: "enterpriseList",
				name: "enterpriseList",
				title: "企业列表",
				component: () => import("../views/adminManage/business/enterpriseList.vue"),
			},
			{
				path: "enterpriseInfo",
				name: "enterpriseInfo",
				title: "企业详情",
				component: () => import("../views/adminManage/business/enterpriseInfo.vue"),
			},
			{
				path: "enterpriseVerify",
				name: "enterpriseVerify",
				title: "企业审核列表",
				component: () => import("../views/adminManage/business/enterpriseVerify.vue"),
			},
			{
				path: "enterpriseVerifyInfo",
				name: "enterpriseVerifyInfo",
				title: "企业审核",
				component: () => import("../views/adminManage/business/enterpriseVerifyInfo.vue"),
			},
			{
				path: "prefix",
				name: "prefix",
				title: "前缀管理",
				component: () => import("../views/adminManage/business/prefix.vue"),
			},
			{
				path: "prefixDetail",
				name: "prefixDetail",
				title: "前缀详情",
				component: () => import("../views/adminManage/business/prefixDetail.vue"),
			},
			{
				path: "prefixSetting",
				name: "prefixSetting",
				title: "前缀配置",
				component: () => import("../views/adminManage/business/prefixSetting.vue"),
			},
			{
				path: "depositSetting",
				name: "depositSetting",
				title: "托管配置",
				component: () => import("../views/adminManage/business/depositSetting.vue"),
			},
			{
				path: "depositDetail",
				name: "depositDetail1",
				title: "托管配置详情",
				component: () => import("../views/adminManage/business/depositDetail.vue"),
			},
			{
				path: "changeRecordsList",
				name: "changeRecordsList",
				title: "变更记录",
				component: () => import("../views/adminManage/business/changeRecordsList.vue"),
			},
		],
	},
	{
		path: "/mark",
		name: "mark",
		title: "标识管理",
		redirect: "/mark/markList",
		component: Home,
		children: [
			{
				path: "markList",
				name: "markList",
				title: "标识列表",
				component: () => import("../views/adminManage/business/mark.vue"),
			},
			{
				path: "markDetail",
				name: "markDetail",
				title: "标识列表",
				component: () => import("../views/adminManage/business/markDetail.vue"),
			},
		],
	},
	{
		path: "/serve",
		name: "serve",
		title: "服务单位管理",
		redirect: "/serve/serveList",
		component: Home,
		children: [
			{
				path: "serveList",
				name: "serveList",
				title: "服务单位列表",
				component: () => import("../views/adminManage/business/serve.vue"),
			},
		],
	},
	{
		path: "/app",
		name: "app",
		title: "应用管理",
		redirect: "/app/appPublishCheck",
		component: Home,
		children: [
			{
				path: "appPublishCheck",
				name: "appPublishCheck",
				title: "应用发布资质审核",
				component: () => import("../views/adminManage/business/appPublishCheck.vue"),
			},
			{
				path: "appCheck",
				name: "appCheck",
				title: "应用审核",
				component: () => import("../views/adminManage/business/appCheck.vue"),
			},
		],
	},
	{
		path: "/publishNotice",
		name: "publishNotice",
		title: "公告通知",
		redirect: "/publishNotice/noticePublish",
		component: Home,
		children: [
			{
				path: "noticePublish",
				name: "noticePublish",
				title: "通知发布",
				component: () => import("../views/adminManage/business/noticePublish.vue"),
			},
		],
	},
	{
		path: "/settingCenter",
		name: "settingCenter",
		title: "配置中心",
		redirect: "/settingCenter/settingCommon",
		component: Home,
		children: [
			{
				path: "settingCommon",
				name: "settingCommon",
				title: "公共服务配置",
				component: () => import("../views/adminManage/business/settingCommon.vue"),
			},
			{
				path: "settingBanner",
				name: "settingBanner",
				title: "banner配置",
				component: () => import("../views/adminManage/business/settingBanner.vue"),
			},
			{
				path: "settingAppType",
				name: "settingAppType",
				title: "应用类别配置",
				component: () => import("../views/adminManage/business/settingAppType.vue"),
			},
		],
	},
	{
		path: "/protocolManagement",
		name: "protocolManagement",
		title: "协议管理",
		redirect: "/protocolManagement/protocolList",
		component: Home,
		children: [
			{
				path: "protocolList",
				name: "protocolList",
				title: "协议列表",
				component: () => import("../views/adminManage/business/protocolList.vue"),
			},
		],
	},
	{
		path: "/openAbilityManagement",
		name: "openAbilityManagement",
		title: "开发能力管理",
		redirect: "/openAbilityManagement/openAbilityList",
		component: Home,
		children: [
			{
				path: "openAbilityList",
				name: "openAbilityList",
				title: "标识解析接入",
				component: () => import("../views/adminManage/business/abilityList.vue"),
			},
		],
	},
	{
		path: "/finance",
		name: "finance",
		title: "财务管理",
		redirect: "/finance",
		component: Home,
		children: [
			{
				path: "/finance/subscription",
				name: "subscription",
				title: "订阅管理",
				component: () => import("../views/adminManage/business/subscription.vue"),
			},
		],
	},
	{
		path: "/user",
		name: "user",
		title: "用户管理",
		redirect: "/user/userList",
		component: Home,
		children: [
			{
				path: "userList",
				name: "userList",
				title: "用户列表",
				component: () => import("../views/adminManage/platform/userList.vue"),
			},
		],
	},
	{
		path: "/role",
		name: "role",
		title: "角色管理",
		redirect: "/role/roleList",
		component: Home,
		children: [
			{
				path: "roleList",
				name: "roleList",
				title: "角色列表",
				component: () => import("../views/adminManage/platform/roleList.vue"),
			},
		],
	},
	{
		path: "/rolePermission",
		name: "rolePermission",
		title: "权限管理",
		redirect: "/rolePermission/rolePermissionList",
		component: Home,
		children: [
			{
				path: "rolePermissionList",
				name: "rolePermissionList",
				title: "权限版本",
				component: () => import("../views/adminManage/platform/rolePermissionList.vue"),
			},
		],
	},
	{
		path: "/financialReport",
		name: "financialReport",
		title: "财务报表",
		redirect: "/financialReport/paymentList",
		component: Home,
		children: [
			{
				path: "paymentList",
				name: "paymentList",
				title: "缴费管理",
				component: () => import("../views/adminManage/financial/paymentList.vue"),
			},
			{
				path: "financialEdit",
				name: "financialEdit",
				title: "缴费编辑",
				component: () => import("../views/adminManage/financial/financialEdit.vue"),
			},
			{
				path: "financialDetail",
				name: "financialDetail",
				title: "缴费详情",
				component: () => import("../views/adminManage/financial/financialDetail.vue"),
			},
		],
	},
	{
		path: "/financial",
		name: "financial",
		title: "收费管理",
		redirect: "/financial/chargeTypeList",
		component: Home,
		children: [
			{
				path: "chargeTypeList",
				name: "chargeTypeList",
				title: "收费类型列表",
				component: () => import("../views/adminManage/financial/chargeTypeList.vue"),
			},
			{
				path: "chargeStandard",
				name: "chargeStandard",
				title: "收费标准列表",
				component: () => import("../views/adminManage/financial/chargeStandard.vue"),
			},
			{
				path: "enterpriseList",
				name: "enterpriseList",
				title: "收费企业列表",
				component: () => import("../views/adminManage/financial/enterpriseList.vue"),
			},
		],
	},
	{
		path: "/userCenter",
		name: "userCenter",
		title: "个人中心",
		redirect: "/userCenter/userInfo",
		component: Home,
		children: [
			{
				path: "userInfo",
				name: "userInfo",
				title: "基本信息",
				component: () => import("../views/adminManage/platform/userInfo.vue"),
			},
			{
				path: "updatePassword",
				name: "updatePassword",
				title: "修改密码",
				component: () => import("../views/adminManage/platform/updatePassword.vue"),
			},
			{
				path: "loginLog",
				name: "loginLog",
				title: "登录日志",
				component: () => import("../views/adminManage/platform/loginLog.vue"),
			},
		],
	},
	// 存证管理(管理端)
	{
		path: "/depositoryManagementAdmin",
		name: "depositoryManagementAdmin",
		title: "存证管理",
		redirect: "/depositoryManagementAdmin/depositoryManagementList",
		component: Home,
		children: [
			{
				path: "quotaSet",
				name: "quotaSet",
				title: "额度配置",
				component: () => import("../views/adminManage/depositoryManagement/quotaSet.vue"),
			},
			{
				path: "modelSet",
				name: "modelSet",
				title: "模板配置",
				component: () => import("../views/adminManage/depositoryManagement/modelSet.vue"),
			},
			{
				path: "modelAdd",
				name: "modelAdd",
				title: "新增模板",
				component: () => import("../views/adminManage/depositoryManagement/modelAdd.vue"),
			},
			{
				path: "modelDetail",
				name: "modelDetail",
				title: "模板详情",
				component: () => import("../views/adminManage/depositoryManagement/modelDetail.vue"),
			},
			{
				path: "depositoryManagementList",
				name: "depositoryManagementList",
				title: "存证列表",
				component: () => import("../views/adminManage/depositoryManagement/depositoryManagementList.vue"),
			},
			{
				path: "depositoryPayed",
				name: "depositoryPayed",
				title: "已购存证",
				component: () => import("../views/adminManage/depositoryManagement/depositoryPayed.vue"),
			},
		],
	},
	{
        path:"/picPreView",
        name:"picPreView",
        title:"预览",
        redirect:"",
        component:() => import('../views/commonUI/picPreView.vue')
    },
	{
		path: "/secendChart",
		name: "secendChart",
		title: "可视化",
		redirect: "",
		component: () => import("../views/adminManage/secendChart/secendChart.vue"),
	},
	{
		path: "*",
		name: "notFind",
		title: "404",
		redirect: "",
		component: () => import("../views/404.vue"),
	},
];

export default basicRoutes;
