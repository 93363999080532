import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
// import routes from './router/index.js'
import routes from './router/router'

import { _http } from './js/utils/http';
import _comm from './js/utils/common';
// import _config from './js/utils/config';
import storage from '@/js/utils/storage';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/base.scss';

import VueAMap from 'vue-amap';
import VueAnimateNumber from 'vue-animate-number'; //数字翻牌

// import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
// import 'quill/dist/quill.bubble.css';
import quilleditor from './components/quilleditor';
import wangEditor from './components/wangEditor';

import customPagination from './components/customPagination';
import customPagination2 from './components/customPagination2';

const router = new VueRouter({
  mode: 'hash',
  routes: routes
})
router.beforeEach((to, from, next) => {
    console.log(to)
    console.log(from)
    next();
});
// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => { });
}

VueAMap.initAMapApiLoader({
  // 高德的key:2faaca36dc73b043479bb59b21e697fc
    key: '2faaca36dc73b043479bb59b21e697fc',
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'MarkerClusterer', 'AMap.Heatmap', 'AMap.DistrictLayer'],
    // plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation', 'AMap.Geocoder'],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.15'
        // v: '1.4.4'
});

Vue.use(VueRouter);
Vue.use(ElementUI);
//重写message只显示最新一个
import resetMessage from './js/utils/message';
Vue.prototype.$message = resetMessage;
Vue.use(VueAMap);
Vue.use(VueAnimateNumber);
// Vue.use(VueQuillEditor);
Vue.use(customPagination);
Vue.use(customPagination2);
Vue.component('quill-editor', quilleditor);
Vue.component('wang-editor', wangEditor);

Vue.prototype._http = _http; //api调用方式
Vue.prototype._comm = _comm; //公共方法使用
Vue.prototype._storage = storage;
// Vue.prototype._config = _config; //配置文件参数
Vue.config.productionTip = false

// 添加防抖
import { throttle } from '@/js/utils/constant.js';
Vue.directive('throttle',throttle)
// 2. 按钮上使用指令
//<button v-throttle="2000" @click="">按钮防抖</button>
//<el-button v-throttle="2000" @click="">按钮防抖</el-button>

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
