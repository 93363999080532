<template>
	<div style="border: 1px solid #ccc">
		<Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
		<Editor style="height: 300px; overflow-y: hidden" v-model="html" @customPaste="customPaste" :defaultConfig="editorConfig" :mode="mode" @onChange="onChange" @onCreated="onCreated" />
	</div>
</template>
<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import axios from "axios";
export default {
	components: { Editor, Toolbar },
	props: {
		content: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			picurl: "",
			editor: null,
			html: "",
			toolbarConfig: {},
			editorConfig: {
				placeholder: "请输入内容...",
				MENU_CONF: {
					//   配置上传图片
					uploadImage: {
						customUpload: this.uploadImage,
					},
				},
			},
			mode: "default", // 'default' or 'simple'
		};
	},
	methods: {
		// 清除粘贴格式
		customPaste(editor, event, callback) {
			const text = event.clipboardData.getData("text/plain"); // 获取粘贴的纯文本
			// let limitLength = 5000;
			// 防止粘贴过多内容
			// const html = event.clipboardData.getData("text/html"); // 获取粘贴的html
			// if (text.length > limitLength) {
			// 	this.$message.warning(`复制内容超过${limitLength}个字符`);
			// }else{
            //     editor.insertText(text);
            // }
			editor.insertText(text);
			event.preventDefault();
			callback(false);
		},
		uploadImage(file, insertFn) {
			let _hostname = location.hostname == "localhost" ? "http://182.151.11.69:41070" : location.origin;
			let formData = new FormData();
			formData.append("file", file);
			let config = {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			axios.post(_hostname + "/v1/common/uploadFile", formData, config).then((res) => {
				// console.log("上传成功拉:",res);
				if (res.data.code == "SUCCESS") {
					insertFn(res.data.data.fileSign);
				} else {
					this.$message.error(res.data.info);
				}
			});
		},
		onChange(editor) {
			this.$emit("changeData", this.html);
		},
		onCreated(editor) {
			this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
			this.toolbarConfig = {
				toolbarKeys: [
					//自定义菜单选项
					// 菜单 key
					// "headerSelect",
					// // 分割线
					// "|",
					"bold",
					// // 菜单 key
					"italic",
					// // 菜单组，包含多个菜单
					// {
					//   key: "group-more-style", // 必填，要以 group 开头
					//   title: "更多样式", // 必填
					//   iconSvg: "<svg>123</svg>", // 可选
					//   menuKeys: ["through", "code", "clearStyle"], // 下级菜单 key ，必填
					// },
					"underline",
					"fontSize",
					"lineHeight",
					"|",
					"bulletedList",
					"numberedList",
					"justifyLeft",
					"justifyRight",
					"justifyCenter",
					"justifyJustify",
					"|",
					"emotion",
					"insertLink",
					"uploadImage",
					// "group-image",
					"codeBlock",
					"divider",
					"|",
					"undo",
					"redo",

					// "color",
					//  "bgColor",
				],
				excludeKeys: [
					// 'fullScreen',
					// 'bold', 'underline', 'italic', 'through', 'code', 'sub', 'sup', 'clearStyle', 'color', 'bgColor', 'fontSize', 'fontFamily',
					//  'indent', 'delIndent', 'justifyLeft', 'justifyRight', 'justifyCenter', 'justifyJustify', 'lineHeight', 'insertImage', 'deleteImage',
					//  'editImage', 'viewImageLink', 'imageWidth30', 'imageWidth50', 'imageWidth100', 'divider', 'emotion', 'insertLink', 'editLink',
					// 'unLink', 'viewLink', 'codeBlock', 'blockquote', 'headerSelect', 'header1', 'header2', 'header3', 'header4', 'header5', 'todo',
					// 'redo', 'undo', 'fullScreen', 'enter', 'bulletedList', 'numberedList', 'insertTable', 'deleteTable', 'insertTableRow',
					// 'deleteTableRow', 'insertTableCol', 'deleteTableCol', 'tableHeader', 'tableFullWidth', 'insertVideo', 'uploadVideo', 'editVideoSize',
					//  'uploadImage', 'codeSelectLang','group-more-style
					"sub",
					"sup",
				],
			};
		},
	},
	created() {
		this.html = this.content;
	},
	mounted() {},
	beforeDestroy() {
		const editor = this.editor;
		if (editor == null) return;
		editor.destroy(); // 组件销毁时，及时销毁编辑器
	},
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
